@tailwind base;
@tailwind components;
@tailwind utilities;

/* Custom Styles */
.bg-silver {
  background-color: #C0C0C0;
}

.text-silver {
  color: #C0C0C0;
}

/* Ensure no extra margins/paddings causing overflow */
body, html {
  margin: 0;
  padding: 0;
  width: 100%;
  overflow-x: hidden;
}
